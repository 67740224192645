<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 기계분류 -->
          <c-select
            type="search"
            codeGroupCd="HHM_TYPE_CD"
            itemText="codeName"
            itemValue="code"
            name="machineryClassification"
            label="기계분류"
            v-model="searchParam.machineryClassification"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 담당부서 -->
          <c-dept
            type="search" 
            label="담당부서" 
            name="departmentDeptCd" 
            v-model="searchParam.departmentDeptCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 담당자 -->
          <c-field 
            type="user" 
            label="담당자" 
            name="departmentUserId" 
            v-model="searchParam.departmentUserId" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 등급 -->
          <c-select
            type="search"
            codeGroupCd="HAZARD_GRADE_CD"
            itemText="codeName"
            itemValue="code"
            name="gradeCd"
            label="등급"
            v-model="searchParam.gradeCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 검사결과 -->
          <c-select
            type="search"
            codeGroupCd="HAZARD_RESULT_CD"
            itemText="codeName"
            itemValue="code"
            name="resultCd"
            label="검사결과"
            v-model="searchParam.resultCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 검사상태 여부 -->
          <c-select
            type="search"
            itemText="codeName"
            itemValue="code"
            :comboItems="targetCdItems"
            :editable="editable"
            label="검사상태 여부"
            name="inspectionSubjectFlag"
            v-model="searchParam.inspectionSubjectFlag">
          </c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 사용여부 -->
          <c-select
            :comboItems="useFlagItems"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="useFlag"
            label="사용여부"
            v-model="searchParam.useFlag"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="유해위험기계기구 목록"
      tableId="equipmentInsp"
      :columns="grid.columns"
      :data="grid.data"
      :checkClickFlag="false"
      rowKey="hhmHazardousMachineryId"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <div>
          <q-btn-group outline>
            <c-btn v-if="editable" label="결과 일괄 업로드" icon="upload"  @btnClicked="openExcelUploader"/>
            <c-btn label="신규등록" v-if="editable" icon="add" @btnClicked="onItemClick" />
            <c-btn label="검색" icon="search" @btnClicked="getList" />
          </q-btn-group>
        </div>
      </template>
      <template v-slot:customArea="{ props }">
        <template v-if="props.row['inspectionSubjectFlag']">
          <q-chip
            :color="setTagColor(props.row.inspectionSubjectFlag)"
            :class="props.row.inspectionSubjectFlag ==='A' ? 'blinking' : null"
            text-color="white"
            >
            <q-avatar v-if="props.row.inspectionSubjectFlag==='A'" icon="alarm" color="red" text-color="white" />
            {{setTagName(props.row.inspectionSubjectFlag)}}
          </q-chip>
        </template>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: "hazard-equipment-class",
  data() {
    return {
      grid: {
        columns: [
          {
            name: "plantName",
            field: "plantName",
            label: "사업장",
            style: 'width:80px', 
            align: "center",
            sortable: true,
          },
          {
            name: "machineryName",
            field: "machineryName",
            label: "기계명",
            style: 'width:200px', 
            align: "left",
            sortable: true,
            type: "link",
          },
          {
            name: "inspectionSubjectFlag",
            field: "inspectionSubjectFlag",
            label: "검사상태",
            type: 'custom',
            align: "center",
            sortable: true,
          },
          {
            name: "itemNo",
            field: "itemNo",
            label: "item 번호",
            style: 'width:150px', 
            align: "center",
            sortable: true,
          },
          {
            name: "machineryClassificationName",
            field: "machineryClassificationName",
            label: "기계분류",
            style: 'width:170px', 
            align: "center",
            sortable: true,
          },
          {
            name: "amount",
            field: "amount",
            label: "수량",
            align: "center",
            sortable: true,
          },
          {
            name: "processName",
            field: "processName",
            label: "단위공정",
            style: 'width:150px', 
            align: "center",
            sortable: true,
          },
          {
            name: "departmentDeptName",
            field: "departmentDeptName",
            label: "담당부서",
            style: 'width:150px', 
            align: "left",
            sortable: true,
          },
          {
            name: "departmentUserName",
            field: "departmentUserName",
            label: "담당자",
            align: "center",
            sortable: true,
          },
          {
            name: "resultCd",
            field: "resultCd",
            label: "검사결과",
            type: 'tag',
            colorItems: [
              { stepperMstCd: 'HRC000005', stepperMstNm: '합격', colorClass: 'blue' },
              { stepperMstCd: 'HRC000010', stepperMstNm: '불합격', colorClass: 'red' },
            ],
            align: "center",
            sortable: true,
          },
          {
            name: "gradeName",
            field: "gradeName",
            label: "등급",
            align: "center",
            style: 'width:50px', 
            sortable: true,
          },
          {
            name: "inspectionKindName",
            field: "inspectionKindName",
            label: "검사종류",
            align: "center",
            sortable: true,
            style: 'width:70px', 
          },
          {
            name: "inspectionCycleName",
            field: "inspectionCycleName",
            label: "검사주기",
            align: "center",
            sortable: true,
          },
          {
            name: "lastInspectionDate",
            field: "lastInspectionDate",
            label: "최근검사일",
            align: "center",
            sortable: true,
          },
          {
            name: "nextInspectionDate",
            field: "nextInspectionDate",
            label: "차기검사일",
            align: "center",
            sortable: true,
          },
          {
            name: "inspCertificateIssuanceDate",
            field: "inspCertificateIssuanceDate",
            label: "검사증교부일",
            align: "center",
            sortable: true,
          },
          {
            name: "useFlagName",
            field: "useFlagName",
            label: "사용여부",
            align: "center",
            sortable: true,
          },
        ],
        data: [],
      },
      searchParam: {
        plantCd: null,
        machineryClassification: null,
        // relatedLawList: [],
        inspectionSubjectFlag: null,
        resultCd: null,
        gradeCd: null,
        inspectionKindCd: null,
        inspectionCycle: null,
        departmentDeptCd: '',
        departmentUserId: '',
        startYmd: '',
        endYmd: '',
        useFlag: 'Y'
      },
      period: ['', ''],
      editable: true,
      colorItems: [
        { stepperMstCd: 'Y', stepperMstNm: '대상', colorClass: 'green' },
        { stepperMstCd: 'N', stepperMstNm: '비대상', colorClass: 'orange' },
        { stepperMstCd: 'A', stepperMstNm: '지연', colorClass: 'red' },
      ],
      targetCdItems: [
        { code: "Y", codeName: "대상" },
        { code: "N", codeName: "비대상" },
        { code: "A", codeName: "지연" },
      ], // 대상여부
      listUrl: '',
      deleteUrl: '',
      popupOptions: {
        isFull: true,
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
      useFlagItems: [
        { code: 'Y', codeName: '사용' },
        { code: 'N', codeName: '미사용' },
      ],
      isResult: false,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {},
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.sop.hhm.hazard.check.list.url;
      this.deleteUrl = transactionConfig.sop.hhm.hazard.check.delete.url;
      // list setting
      this.getList();
    },
    getList() {
      if (this.period) {
        this.searchParam.startYmd = this.period[0];
        this.searchParam.endYmd = this.period[1];
      } else {
        this.searchParam.startYmd = '';
        this.searchParam.endYmd = '';
      }
      // 전체조회
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    /* eslint-disable no-unused-vars */ 
    linkClick(row, col, index) {
      this.popupOptions.title = "유해위험기계기구 상세"; // 유해위험기계기구 상세
      this.popupOptions.param = {
        hhmHazardousMachineryId: row.hhmHazardousMachineryId ? row.hhmHazardousMachineryId : '',
      };
      this.popupOptions.target = () =>
        import(`${"./hazardEquipmentClassDetail.vue"}`);
      this.popupOptions.visible = true;
      this.popupOptions.height = '300px';
      this.popupOptions.isFull = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup(type) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
    onItemClick() {
      this.popupOptions.target = () =>
      import(`${"./hazardEquipmentClassDetail.vue"}`);
      this.popupOptions.isFull = true;
      this.popupOptions.title = '유해위험기계기구 상세'; // 유해위험기계기구 상세
      this.popupOptions.param = {
        hhmHazardousMachineryId: '',
      };
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    
    setTagColor(data) {
      if (this.colorItems) {
        let color = this.$_.find(this.colorItems, { code: data }) || this.$_.find(this.colorItems, { stepperMstCd: data })
        return color ? color.attrVal1 || color.colorClass : '';
      } else {
        return ''
      }
    },
    setTagName(data) {
      if (this.colorItems) {
        let color = this.$_.find(this.colorItems, { code: data }) || this.$_.find(this.colorItems, { stepperMstCd: data })
        return color ? color.codeName || color.stepperMstNm : '';
      } else {
        return ''
      }
    },
     openExcelUploader() {
      this.popupOptions.title = '유해위험기계기구 업로드'; // 유해위험기계기구 업로드
      this.popupOptions.param = {}
      this.popupOptions.target = () => import(`${'./hazardResultExcelUpload.vue'}`);
      this.popupOptions.isFull = true;
      this.popupOptions.width = '90%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeExcelUploadPopup;
    },
    closeExcelUploadPopup(_result, plantCd) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (_result && _result.length > 0) {
        let s_data = this.$_.filter(_result, (item) => { return !item.error_message })
        this.$_.forEach(s_data, item => {
          item.regUserId = this.$store.getters.user.userId;  // 등록자 ID
          item.chgUserId = this.$store.getters.user.userId;  // 수정자 ID
          item.departmentUserId = this.$store.getters.user.userId; 
          // item.departmentDeptCd = this.$store.getters.user.deptCd;  
          item.plantCd = plantCd;
          item.editFlag = 'C';
        })
        this.$http.url = transactionConfig.sop.hhm.hazard.check.uploadSave.url;
        this.$http.type = 'POST';
        this.$http.param = s_data;
        this.$http.request(() => {
          window.getApp.$emit('APP_REQUEST_SUCCESS');
          this.getList();
        },);
      }
    }
  },
};
</script>
<style>
.blinking {
  -webkit-animation:blink 3s ease-in-out infinite alternate;
  -moz-animation:blink 3s ease-in-out infinite alternate;
  animation:blink 3s ease-in-out infinite alternate;
}
@-webkit-keyframes blink {
  0% { opacity:0 }
  100% { opacity:1 }
}
@-moz-keyframes blink {
  0% { opacity:0 }
  100% { opacity:1 }
}
@keyframes blink {
  0% { opacity:0 }
  100% { opacity:1 }
}
</style>